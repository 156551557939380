















































































import {
  watch,
  toRefs,
  computed,
  reactive,
  onBeforeUnmount,
  defineComponent,
  SetupContext,
} from '@vue/composition-api';

// Definitions
import { Response } from './FormRegister.specs';

export default defineComponent({
  name: 'FormRegister',
  setup(props, { emit }: SetupContext) {
    const state = reactive({
      fullName: '',
      login: '',
      password: '',
      loading: false,
      disabled: false,
      disabledButton: false,
    });

    const formValid = computed(
      () => !!(state.fullName && state.login && state.password),
    );

    const methods = {
      on: {
        clickCloseButton: () => emit('close'),
        registerButton: () => {
          const { fullName, login, password } = state;
          state.disabled = true;
          state.loading = true;
          state.disabledButton = true;
          emit('complete', { fullName, login, password } as Response);
        },
      },
    };

    watch(
      () => formValid,
      isValid => {
        state.disabled = !isValid;
      },
    );

    onBeforeUnmount(() => {
      state.fullName = '';
      state.login = '';
      state.password = '';
      state.loading = false;
      state.disabled = false;
    });

    return {
      ...methods,
      ...toRefs(state),
    };
  },
});
