






import { watch, defineComponent, SetupContext } from '@vue/composition-api';

// Components
import Dialog from '../../atoms/Dialog.vue';
import FormRegister, { Response } from '../../molecules/FormRegister';

export default defineComponent({
  name: 'DialogRegister',
  components: {
    Dialog,
    FormRegister,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { root, emit }: SetupContext) {
    const methods = {
      on: {
        close: () => emit('close'),
        complete: async (response: Response) => {
          await root.$store.dispatch('events/signUp', response);
        },
      },
    };

    watch(
      () => props.open,
      isOpen => {
        if (isOpen) root.$store.dispatch('events/initialize');
      },
    );

    return { ...methods };
  },
});
